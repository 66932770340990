<template>
    <v-container>
        <v-row justify="center">
            <h1>{{ $t('general.welcome') }}</h1>
        </v-row>
        <v-row>
            <v-col cols="4" sm="4" md="4" lg="4" justify="center">
                <v-btn v-if="canCreateItem" color="dark" dark class="ma-2 custom_button mobile_button"
                       @click="openItemDialog('create', 0)" :title="$t('clients.create')">
                    <v-icon small size="20">face</v-icon>
                    {{ $t('clients.create') }}
                </v-btn>
                <v-btn v-if="canCreateItem" color="dark" dark class="custom_button mobile_button"
                       :title="$t('clients.create')" to="/create_invoice">
                    <v-icon small size="20">add</v-icon>
                    {{ $t('invoices.create') }}
                </v-btn>
            </v-col>
        </v-row>

        <!--Item Dialog-->
        <ManageClientDialog :show="show_item_dialog" :item_header="item_dialog_header"
                            :is_loading="item_dialog_loading" :item_action="item_action" :item_id="item_id"
                            @close-dialog="closeItemDialog" @submit-dialog="submitItemDialog">
        </ManageClientDialog>
    </v-container>
</template>

<script type="text/babel">

    import ManageClientDialog from '../components/ManageClientDialog.vue';

    export default {
        components: {
            ManageClientDialog
        },
        data: self => ({
            url: 'clients',

            // actions permissions
            canCreateItem: false,

            item_id: null,

            // manage item
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item_action: null
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        async mounted() {
            this.canCreateItem = await this.$store.dispatch('hasPermission', 'create_client');
        },
        methods: {
            // get data from API
            async getData() {
                this.loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get', url: this.url, params: {
                        options: this.options, search_filters: this.search_filters
                    }
                });


                this.loading = false;

                // successful response
                if (!response.response) {
                    this.total_items = response.data.total_items;
                    this.items = response.data.items;
                }
            },

            // Manage Item
            openItemDialog(action, id) {
                this.item_dialog_header = (action === 'create') ? this.$t('clients.create')
                    : (action === 'update') ? this.$t('clients.update') : this.$t('clients.view');
                this.item_id = id;
                this.item_action = action;
                this.show_item_dialog = true;
            },
            // clear item dialog related data
            clearItemDialog() {
                this.item_dialog_header = null;
                this.item_id = null;
                this.item_action = null;
            },
            // close item dialog
            closeItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
            },

            // submit item dialog
            submitItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
            }
        }
    }
</script>
